import { Badge } from "antd";
import useCart from "grandus-lib/hooks/useCart";
import { get } from "lodash";

import styles from "components/header/Header.module.scss";

import Link from "next/link";
import { LoadingOutlined, ShoppingOutlined } from "@ant-design/icons";

const CartButton = () => {
  const { cart, isLoading } = useCart();
  return (
    <Link href="/kosik" as={`/kosik`}>
      <a>
        <div className={styles.icon}>
          <Badge count={get(cart, "pieceCount", 0)}>
            <div
              style={{
                width: "32px",
                height: "32px",
                display: "inline-block",
              }}
            >
              {isLoading ? (
                <LoadingOutlined />
              ) : (
                <ShoppingOutlined className={"iconAnd"} />
              )}
            </div>
          </Badge>
        </div>
        {get(cart, "sumData.priceFormatted", "košík")}
      </a>
    </Link>
  );
};

export default CartButton;
