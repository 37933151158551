import { get } from "lodash";
import styles from "./InfoBox.module.scss";

const InfoBox = ({ data }) => {
  const content = get(data, "[0].text");

  return (
    <div
      className={styles.wrapper}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

export default InfoBox;
